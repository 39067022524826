import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import ExperienceView from './experience-view'

const ExperienceController = (props) => {
  const { slug } = props
  const [loading, setLoading] = useState(false)
  const [experience, setExperience] = useState({})
  const { i18n } = useTranslation()

  const getExperienceBySlug = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'experienceLife',
      params: {
        slug,
        locale: i18n.language,
      },        
    })
      .then(({ data }) => {
        setExperience(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {    
    getExperienceBySlug()
  }, [slug, i18n.language])


  const viewProps = {
    data: experience,
    loading,
  }
  return <ExperienceView {...viewProps} />
}

export default ExperienceController
