import React from 'react'
import './related-plans-styles.scss'
import { CarouselCards, CardProduct } from '../../../../components'

const RelatedPlans = ({ products }) => (
  <div className="container-xxl experienceRelated__container">
    <CarouselCards
      arrows={true}
      data={products}
      parentLink="product"
      inner={CardProduct}
      tag="Related Plans"
    />
  </div>
)

export default RelatedPlans
