import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Cta, Spinner, Seo } from '../../components'
import { BlogCarousel, HeadExperiences, RelatedPlans } from './components'
import './experience-style.scss'

const ExperienceView = ({ loading, data }) => {
  const { t, i18n } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
    
  const statement = _.get(data, 'statement');
  const relatedProducts = _.get(data, 'related_products');

  // If statement is empty or null, and there are related products, don't hide the entire view
  if (!statement && relatedProducts) {
    return (
      <div className="experience__container container-fluid px-0">
        <Seo title={`${t('seo.title')} Experiences`} />
        <HeadExperiences t={t} data={data} />
        <BlogCarousel t={t} i18n={i18n} blogs={_.get(data, 'featured_blogs', data.featured_blog)} />
        <RelatedPlans t={t} products={relatedProducts} />
        <div className="container-xxl px-0">
          <Cta
            withAppend={true}
            info={{
              buttonText: t('product.header.buttonText'),
            }}
          /> 
        </div>
      </div>
    );
  }

  // If statement is empty or null, and there are no related products, hide the entire view
  if (!statement) {
    return null;
  }

  // Render the full view if statement is not empty and there are related products
  return (    
    <div className="experience__container container-fluid px-0">
      <Seo title={`${t('seo.title')} Experiences`} />
      <HeadExperiences t={t} data={data} />
      <BlogCarousel t={t} i18n={i18n} blogs={_.get(data, 'featured_blogs', data.featured_blog)} />
      <div className="container-xxl experience__content">
        <h2 className="experience__title">{statement}</h2>
      </div>
      <RelatedPlans t={t} products={relatedProducts} />
      <div className="container-xxl px-0">
        <Cta
          withAppend={true}
          info={{
            buttonText: t('product.header.buttonText'),
          }}
        />
      </div>
    </div>
  )
}

export default ExperienceView
