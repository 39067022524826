import React from 'react'
import { CarouselCards, CardWithPicture } from '../../../../components'
import './blog-carousel-style.scss'

const BlogCarousel = ({ blogs }) => {
  // Check if blogs is empty or null
  if (!blogs || blogs.length === 0) {
    return (
    <div className="container-xxl experienceBlog__container" style={{ height: '50px' }}>
    {/* You can adjust the height value as needed */}
  </div>); // Don't render anything if blogs is empty or null
  }

  return (
    <div className="container-xxl experienceBlog__container">
      <CarouselCards
        data={blogs}
        arrows={true}
        tag="Featured blogs"
        parentLink="blog"
        inner={CardWithPicture}
        extraProps={{
          pictureDisplace: true,
        }}
      />
    </div>
  );
}

export default BlogCarousel
